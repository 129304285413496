.numeric-tiles {
	-ms-overflow-style: none; /* for Internet Explorer, Edge */
	scrollbar-width: none; /* for Firefox */
	overflow-x: scroll;

	@include breakpoint(mid) {
		display: grid;
		grid-template:
		". . ." 1fr
		". line ." auto
		". . ." 1fr/
		1fr auto 1fr;
	}

	&::-webkit-scrollbar {
		display: none; /* for Chrome, Safari, and Opera */
	}
}

.numeric-tiles__line {
	display: none;
	grid-area: line;
	background: color(red500);
	height: 4px;
	width: calc(100% - 40px);
	margin-left: 20px;
	align-items: center;
	justify-content: flex-end;

	@include breakpoint(mid) {
		display: flex;
	}

	&::after {
		content: "";
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 5px 0 5px 8.7px;
		border-color: #fff #fff #fff color(red500);
	}
}

.numeric-tiles__items {
	display: flex;
	flex-direction: column;
	transition: transform 0.5s ease-in-out;

	@include breakpoint(mid) {
		grid-row: 1 / 4;
		grid-column: 2;
		justify-content: center;
		flex-direction: row;
	}
}

.numeric-tiles__grid {
	&:last-child {
		.numeric-tiles-item:last-child {
			.numeric-tiles-item__number::before {
				bottom: 50%;

				@include breakpoint(mid) {
					bottom: 16px;
				}
			}
		}
	}

	@include breakpoint(mid) {
		display: grid;
		grid-template:
		"top top ."
		". . ." 68px
		". bottom bottom" auto
		/ 146px 110px 146px;

		&:first-child {
			margin-left: 20px;
		}

		&:not(:first-child) {
			margin-left: -106px;
		}

		&:last-child {
			margin-right: 20px;
		}
	}
}

.numeric-tiles__grid--single {
	grid-template:
		"top top" 1fr
		". ." 68px
		". ." 1fr
		/ 146px 110px;
}

.numeric-tiles-item__number {
	z-index: 100;
	position: relative;
	order: 1;

	@include breakpoint(mid) {
		width: 32px;
		height: 66px;
		position: absolute;
		left: calc(50% - 16px);
		bottom: -66px;
	}

	&::before {
		content: "";
		position: absolute;
		background: color(red500);
		left: 0;
		top: 0;
		bottom: 0;
		width: 4px;

		@include breakpoint(mid) {
			left: 14px;
			bottom: 16px;
		}
	}

	&::after {
		content: attr(data-number);
		position: absolute;
		border: 4px solid color(red500);
		background: #fff;
		border-radius: 32px;
		width: 32px;
		height: 32px;
		left: 2px;
		transform: translate(-50%, -50%);
		top: 50%;
		text-align: center;
		font-weight: bold;
		font-size: 18px;
		line-height: 1;
		padding-top: 4px;
		display: block;

		@include breakpoint(mid) {
			left: 50%;
			top: unset;
			bottom: 0;
		}
	}

	&.finished {
		&::after {
			content: "";
			background-color: color(white);
			background-size: 60%;
			background-repeat: no-repeat;
			background-position: center center;
			@include background-svg('<svg xmlns="http://www.w3.org/2000/svg" width="31" height="22" viewBox="0 0 31 22" fill="none"><path d="M0.591797 12.2276C0.591797 11.4542 1.25669 10.8942 1.96602 10.8942C2.49935 10.8942 2.63091 10.9831 2.94024 11.2836L10.2078 18.2934L28.4211 0.572478C28.5477 0.443404 28.7 0.342366 28.8682 0.275909C29.0363 0.209453 29.2165 0.17906 29.3971 0.1867C30.1936 0.1867 30.814 0.8747 30.814 1.52003C30.814 1.86314 30.6825 2.16537 30.3714 2.46581L11.1838 21.1325C10.9212 21.3836 10.5711 21.5226 10.2078 21.52C10.0273 21.5272 9.84738 21.4964 9.67956 21.4296C9.51174 21.3629 9.35981 21.2616 9.23358 21.1325L0.991797 13.1751C0.865117 13.0524 0.764423 12.9054 0.695706 12.7429C0.626989 12.5804 0.591652 12.4058 0.591797 12.2294V12.2276Z" fill="#EC0016"/></svg>');
		}

	}
}

.numeric-tiles-item {
	position: relative;
	z-index: 2;
	margin: 0 20px;
	justify-self: center;
	display: flex;
	justify-content: space-between;
	width: 100%;

	&::before {
		content: "";
		position: absolute;
		background: color(red500);
		left: 0;
		top: 50%;
		width: 100%;
		height: 4px;
	}

	@include breakpoint(mid) {
		display: block;
		padding-top: 0;

		&::before {
			display: none;
		}
	}

	&:first-child {
		grid-area: top;
		align-self: end;
	}

	&:last-child:not(:first-child) {
		grid-area: bottom;
		align-self: start;

		.numeric-tiles-item__number {
			@include breakpoint(mid) {
				bottom: auto;
				top: -66px;

				&::before {
					top: 16px;
					bottom: 0;
				}

				&::after {
					top: 0;
					bottom: auto;
					transform: translate(-50%, 50%);
				}
			}
		}
	}
}

.numeric-tiles-item__content {
	background: #f0f3f5;
	border-radius: 6px;
	padding: 25px 20px 20px;
	width: 256px;
	max-width: calc(100vw - 100px);
	position: relative;
	text-align: center;
	display: flex;
	flex-direction: column;
	min-height: 256px;
	transition: background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
	box-shadow: 0 0 #0000;
	order: 2;
	margin-bottom: 1rem;

	@include breakpoint(mid) {
		margin-bottom: unset;
	}

	&--border {
		border: 2px dashed currentColor;
		padding: 23px 18px 18px;
	}

	&:hover,
	&:focus {
		background: #d7dce1;
		box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
	}

	> div {
		flex: 1;
	}

	h3 {
		font-size: 48px;
		margin: 0;
		text-align: center;
		line-height: 1;
	}

	hr {
		height: 1px;
		border: 0;
		background: #d7dce1;
		margin: 0;
	}

	p {
		margin: 0;
		line-height: 1.3;
		font-size: 18px;
	}
}

.numeric-tiles-item__icon > * {
	width: 56px;
	height: 56px;
	margin: 0 auto;
	object-fit: contain;
}

.numeric-tiles-item__headline {
	background: none;
	border: 0;
	padding: 0;
	font-size: 20px;
	line-height: 1.3;
	margin-top: 10px;
	cursor: pointer;

	> :first-child {
		position: absolute;
		inset: 0;
	}

	> :last-child {
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
	}
}

.numeric-tiles-popup__icon {
	padding: 45px 0;
	background: #f0f3f5;

	> * {
		width: 100%;
		height: 75px;
		object-fit: contain;
	}
}

// For Backend edit reasons
.section--numeric-tiles {
	.carbon-cbd__edit {
		overflow-x: auto;
		padding: 0 30px;
	}

	.carbon-cbd__content-collection {
		display: flex;
		width: fit-content;
	}
}

.numeric-tiles-popup__content--backend {
	min-width: 500px;
}
