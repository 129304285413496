//*******************************************************************************
// Blogpost - with 2 Columns
//*******************************************************************************


.blogpost--columns {
	@include breakpoint(mid) {
		display: grid;
		grid-template-columns: repeat(18, 1fr);
		grid-template-rows: min-content max-content max-content max-content;
		margin-left: 0;
		margin-right: 0;
		padding-bottom: 5vw;
	}

	@include breakpoint(max) {
		grid-template-columns: repeat(16, 1fr);
	}


	.blogpost__date,
	.blogpost__title,
	.blogpost__content,
	.blogpost__taglist,
	.blogpost__readmore-wrapper {
		@include breakpoint(mid) {
			grid-column: 11 / span 8;
			align-self: end;
			margin-left: 2.5vw;
		}

		@include breakpoint(max) {
			grid-column: 11 / span 6;
			margin-left: 0;
		}
	}

	.blogpost__date {
		@include breakpoint(mid) {
			grid-row: 1;
		}
	}

	.blogpost__title {
		margin-bottom: 48px;

		@include breakpoint(mid) {
			grid-row: 2;
			margin-bottom: 4vw;
		}

		@include breakpoint(max) {
			margin-bottom: 2vw;
		}
	}

	.blogpost__mediawrapper {
		margin-bottom: 24px;

		@include breakpoint(mid) {
			grid-column: 1 / span 10;
			grid-row: 1 / span 4;
			align-self: end;
			margin-right: 2.5vw;
			margin-bottom: 0;
		}

		@include breakpoint(max) {
			grid-column: 1 / span 9;
			margin-right: 0;
		}
	}

	.blogpost__mediawrapper .image__desc {
		@include breakpoint(mid) {
			position: absolute;
			left: 0;
			right: 0;
			top: 100%;
		}
	}

	.blogpost__content {
		@include breakpoint(mid) {
			grid-row: 3;
		}
	}

	.blogpost__taglist {
		@include breakpoint(mid) {
			grid-row: 4;
			margin-bottom: 0;
		}
	}

	.blogpost__readmore-wrapper {
		margin-top: 2vw;

		@include breakpoint(mid) {
			grid-row: 5;
		}
	}

	.blogpost__readmore {
		margin-left: -6px;
	}

	.blogpost__collapsed-content {
		margin-top: 5vw;

		@include breakpoint(mid) {
			grid-column: 4 / span 10;
			grid-row: 6;
		}
	}
}



//
// Reversed Version
//
.blogpost--columns-reversed {
	.blogpost__date,
	.blogpost__title,
	.blogpost__content,
	.blogpost__taglist,
	.blogpost__readmore-wrapper {
		@include breakpoint(mid) {
			grid-column: 1 / span 8;
			margin-left: 0;
			margin-right: 2.5vw;
		}

		@include breakpoint(max) {
			grid-column: 1 / span 6;
			margin-right: 0;
		}
	}

	.blogpost__mediawrapper {

		@include breakpoint(mid) {
			grid-column: 9 / span 10;
			margin-left: 2.5vw;
			margin-right: 0;
		}

		@include breakpoint(max) {
			grid-column: 8 / span 9;
			margin-left: 0;
		}
	}
}
