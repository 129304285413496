//*******************************************************************************
// Teaser - Default
//*******************************************************************************

.teaser {
	// outline: 1px solid red;
	margin: 0 5vw;

	@include breakpoint(mid) {
		margin: 0 20vw;
	}

	@include breakpoint(max) {
		margin: 0 15vw;
	}
}

.teaser__title {
	text-align: center;
	margin-bottom: 8vw;

	@include breakpoint(mid) {
		font-size: calc(36px + (64 - 36) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		line-height: calc(40px + (72 - 40) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		margin-bottom: 4vw;
	}

	@include breakpoint(max) {
		font-size: 4vw;
		line-height: 4.5vw;
	}

	&.title--left {
		text-align: left;
	}
}

.teaser__content {
	text-align: center;
	margin-left: auto;
	margin-right: auto;

	p {
		@include font-family(headlight);
		margin-bottom: 4vw;

		@include breakpoint(min) {
			font-size: 18px;
			line-height: 24px;
		}

		@include breakpoint(mid) {
			font-size: calc(24px + (32 - 24) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
			line-height: calc(32px + (40 - 32) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
			margin-bottom: 2vw;
		}

		@include breakpoint(max) {
			font-size: 2vw;
			line-height: 2.5vw;
		}
	}

	.btn {
		margin-bottom: 8vw;

		@include breakpoint(mid) {
			margin-bottom: 4vw;
		}
	}
}



//
// Title with Box-Shadow
//
.teaser--has-shadow {
	@include breakpoint(mid) {
		margin: 0 15vw;
	}

	.teaser__title {
		background-color: color(bright);
		box-shadow: $box-shadow--3;
		border-radius: $border-radius;
		padding: 4vw;
	}
}



//
// Teaser Subpage
//
.teasersubpage {
	margin: 0 5vw;
	padding-top: 3vw;

	@include breakpoint(min) {
		margin: 0 calc(15vw + (160 - 80) * ((100vw - 800px) / (1600 - 800)));
	}

	@include breakpoint(mid) {
		margin: 0 calc(15vw + (160 - 160) * ((100vw - 800px) / (1600 - 800)));
	}

	@include breakpoint(max) {
		margin: 0 15vw;
	}

	.teaser__title,
	.teaser__content {
		text-align: left;
	}
}
