.tab-element {
	background: #f0f3f5;
	padding: 0;
	border-radius: 6px;
	max-width: 850px;
	margin: 0 auto;

	@media (min-width: $breakpoint-sl) {
		padding: 0 25px;
	}

	@media (min-width: $breakpoint-lg) {
		max-width: 70vw;
	}

	& > * + * {
		border-top: 1px #d7dce1 solid;

		@media (min-width: $breakpoint-sl) {
			border-left: 1px #d7dce1 solid;
			border-top: 0;
		}
	}
}

.tab-item {
	display: flex;
	flex-direction: column;
	padding: 40px 15px;

	&.accordion-item--expanded {
		width: 100%;
	}
}

.tab-item__trigger {
	margin: 0;
	padding: 0;
	display: flex;
}

.tab-item__trigger--collapsed {
	flex: 1;
}

.tab-item__button {
	border: 0;
	background: transparent;
	width: 100%;
	color: inherit;
	display: flex;
	flex-direction: column;

	&:not(:disabled) {
		cursor: pointer;
	}

	@media (min-width: $breakpoint-sl) {
		align-items: center;
	}
}

.tab-item__spacer {
	display: flex;
	flex: 1;
	justify-content: center;
	align-content: stretch;
	min-width: 2em;
	position: relative;
	padding: 0 30px;
	width: 100%;

	@media (min-width: $breakpoint-sl) {
		flex-direction: column;
		justify-content: flex-end;
		padding: 0;
		width: auto;
	}
}

.tab-item__spacer--collapsed {
	background: #fff;
	border-radius: 6px;
}

.tab-item__arrows--mobile {
	display: flex;
	align-items: center;
	position: absolute;
	right: 5px;
	top: 0;
	bottom: 0;
	width: 35px;

	svg {
		transform: rotate(90deg) scale(0.7);
		height: 18px;
	}

	@media (min-width: $breakpoint-sl) {
		display: none !important;
	}
}

.tab-item__arrows--desktop {
	display: none;
	margin-top: 10px;

	@media (min-width: $breakpoint-sl) {
		display: block;
		max-width: 32px;
	}
}

.tab-item__icon {
	display: flex;
	align-items: center;
	justify-content: center;
	background: color(red500);
	border-radius: 100%;
	height: 80px;
	width: 80px;
	color: #fff;
	margin: 0 auto 30px;
	padding: 15px;

	@media (min-width: $breakpoint-sl) {
		margin: 40px auto 50px;
	}

	> * {
		max-width: 50px;
		max-height: 50px;
		width: 100%;
		height: auto;
	}
}

.tab-item__headline {
	padding: 10px 0;

	@media (min-width: $breakpoint-sl) {
		padding: 0;
		margin-bottom: 20px;
	}
}

.tab-item__headline--collapsed {
	@media (min-width: $breakpoint-sl) {
		display: inline-block;
		writing-mode: vertical-rl;
		transform: rotate(180deg);
		flex: 1;
		text-align: left;
		white-space: nowrap;
		align-self: center;
		margin-top: 20px;
	}
}

.tab-item__mobile {
	@media (min-width: $breakpoint-sl) {
		display: none !important;
	}
}

.tab-item__desktop {
	display: none;
	transition: color 0.1s linear;

	@media (min-width: $breakpoint-sl) {
		display: block;
	}
}

.tab-item__content {
	min-width: calc(var(--item-width, 50px) - 50px);
}
