//*******************************************************************************
// THIS FILE CONTAINS ALL TYPOGRAPHY DECLARATIONS
//*******************************************************************************

h1, h2, h3, h4, h5, h6, p, li {
	margin-top: 0;
	margin-bottom: 0 ;
}



//
// compact Text Mixin
//
@mixin textcompactsize() {
	font-size: 3.5vw;
	line-height: 5vw;

	@include breakpoint(min) {
		font-size: $textcompactfontsizemin + px;
		line-height: $textcompactlineheightmin + px;
	}

	@include breakpoint(mid) {
		font-size: calc(#{$textcompactfontsizemin}px + (#{$textcompactfontsizemax} - #{$textcompactfontsizemin}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		line-height: calc(#{$textcompactlineheightmin}px + (#{$textcompactlineheightmax} - #{$textcompactlineheightmin}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
	}

	@include breakpoint(max) {
		font-size: 1vw;
		line-height: 1.5vw;
	}
}

// Overlay-Text Mixin
// 2px smaller than bodytextsize
//
@mixin overlaytextsize() {
	font-size: calc(3.5vw - 1px);
	line-height: calc(6vw - 1px);

	@include breakpoint(min) {
		font-size: calc(($textfontsizemin + px) - 1px);
		line-height: calc(($textlineheightmin + px) - 1px);
	}

	@include breakpoint(mid) {
		font-size: calc((#{$textfontsizemin}px + (#{$textfontsizemax} - #{$textfontsizemin}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint}))) - 1px);
		line-height: calc((#{$textlineheightmin}px + (#{$textlineheightmax} - #{$textlineheightmin}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint}))) - 1px);
	}

	@include breakpoint(max) {
		font-size: calc(1.125vw - 1px);
		line-height: calc(2vw - 1px);
	}
}

//
// Body-Text Mixin
//
@mixin bodytextsize() {
	font-size: 3.5vw;
	line-height: 6vw;

	@include breakpoint(min) {
		font-size: $textfontsizemin + px;
		line-height: $textlineheightmin + px;
	}

	@include breakpoint(mid) {
		font-size: calc(#{$textfontsizemin}px + (#{$textfontsizemax} - #{$textfontsizemin}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		line-height: calc(#{$textlineheightmin}px + (#{$textlineheightmax} - #{$textlineheightmin}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
	}

	@include breakpoint(max) {
		font-size: 1.125vw;
		line-height: 2vw;
	}
}

body,
p,
li {
	@include bodytextsize;
}

p,
ul {
	margin-bottom: 6vw;

	@include breakpoint(min) {
		margin-bottom: 24px;
	}

	@include breakpoint(mid) {
		margin-bottom: 3vw;
	}

	@include breakpoint(max) {
		margin-bottom: 2vw;
	}
}



//
// H1 Mixin
//
@mixin headline1() {
	@include font-family(headblack);
	font-size: 7vw;
	line-height: 8vw;
	margin-bottom: 6vw;

	@include breakpoint(min) {
		font-size: calc(#{$h1fontsizemid}px + (#{$h1fontsizemax} - #{$h1fontsizemid}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		line-height: calc(#{$h1lineheightmid}px + (#{$h1lineheightmax} - #{$h1lineheightmid}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		margin-bottom: 24px;
	}

	@include breakpoint(mid) {
		font-size: calc(#{$h1fontsizemid}px + (#{$h1fontsizemax} - #{$h1fontsizemid}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		line-height: calc(#{$h1lineheightmid}px + (#{$h1lineheightmax} - #{$h1lineheightmid}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		margin-bottom: 2vw;
	}

	@include breakpoint(max) {
		font-size: 3vw;
		line-height: 3.5vw;
	}
}



//
// H2 Mixin
//
@mixin headline2() {
	@include font-family(headlight);
	font-size: 4.5vw;
	line-height: 6vw;
	margin-bottom: 12vw;

	@include breakpoint(min) {
		font-size: calc(#{$h2fontsizemid}px + (#{$h2fontsizemax} - #{$h2fontsizemid}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		line-height: calc(#{$h2lineheightmid}px + (#{$h2lineheightmax} - #{$h2lineheightmid}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		margin-bottom: 48px;
	}

	@include breakpoint(mid) {
		font-size: calc(#{$h2fontsizemid}px + (#{$h2fontsizemax} - #{$h2fontsizemid}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		line-height: calc(#{$h2lineheightmid}px + (#{$h2lineheightmax} - #{$h2lineheightmid}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		margin-bottom: 4vw;
	}

	@include breakpoint(max) {
		font-size: 2vw;
		line-height: 2.5vw;
	}
}



//
// H1 & H2
//
.h1, h1 { @include headline1; }
.h2, h2 { @include headline2; }



//
// H3
//
.h3,
h3 {
	@include font-family(headblack);
	font-size: 4vw;
	line-height: 6vw;
	margin-bottom: 6vw;

	@include breakpoint(min) {
		font-size: $h3fontsizemin + px;
		line-height: $h3lineheightmin + px;
		margin-bottom: 24px;
	}

	@include breakpoint(mid) {
		font-size: calc(#{$h3fontsizemin}px + (#{$h3fontsizemax} - #{$h3fontsizemin}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		line-height: calc(#{$h3lineheightmin}px + (#{$h3lineheightmax} - #{$h3lineheightmin}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		margin-bottom: 3vw;
	}

	@include breakpoint(max) {
		font-size: 1.25vw;
		line-height: 2vw;
		margin-bottom: 2vw;
	}
}


h4, h5, h6 {
	@include font-family(headblack);
}


// h1:last-child,
// h2:last-child,
// h3:last-child,
// h4:last-child,
// p:last-child {
// 	margin-bottom: 0;
// }



//
// Font Helpers
//
.text-headlight   { @include font-family(headlight); }
.text-headblack   { @include font-family(headblack); }
.text-sansbold    { @include font-family(sansbold); }
.text-sansregular { @include font-family(sansregular); }


strong,
.strong,
.font-weight-bold {
	@include font-family(sansbold);
	color: inherit;
}



small,
.small {
	font-size: calc(#{$textsmallfontsizemin}px + (#{$textsmallfontsizemax} - #{$textsmallfontsizemin}) * ((100vw - #{$textminbreakpoint}px) / (1600 - #{$textminbreakpoint})));
	line-height: calc(#{$textsmalllineheightmin}px + (#{$textsmalllineheightmax} - #{$textsmalllineheightmin}) * ((100vw - #{$textminbreakpoint}px) / (1600 - #{$textminbreakpoint})));

	@include breakpoint(max) {
		font-size: #{$textsmallfontsizemax}px;
		line-height: #{$textsmalllineheightmax}px;
	}
}



//
// Blockquote
//
.blockquote {

	p {
		font-size: 1.6em;
		line-height: 1.2;
		font-style: italic;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	.blockquote__footer {
		color: #b6c0c6;
		font-size: 1em;
		line-height: 1.5;
		margin-top: 0.5em;
		margin-bottom: 0.5em;
	}
}
