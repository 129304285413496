//*******************************************************************************
// MENU LARGE
//*******************************************************************************

//
// Main Manü
//
.header--large {
	.menu {
		$self: &;
		display: none;
		flex-direction: column;
		margin-bottom: 0;
		width: 100%;

		@include breakpoint($breakpoint-mid) {
			display: flex;
			width: auto;
			position: static;
			flex-direction: row;
			margin-right: -20px;
		}




		/* background-color: red !important; */



		&--open {

			@include breakpoint($max: $breakpoint-mid) {
				display: flex;
				position: absolute;
				top: 60px;
				left: 0;
				right: 0;
				width: 100vw;
				height: 100vh;
				margin: 0;
				padding: 20vw 5vw 60vw;
				overflow: scroll;
				background-color: color(bright);
				z-index: 100;
			}

			/* @include breakpoint($breakpoint-mid) {

				padding: 5vw;

			} */
		}



		/* if --open && width < 800 */
		/*

		&--open {
			display: flex;
			position: absolute;
			top: 60px;
			left: 0;
			right: 0;
			width: 100vw;
			height: 100vh;
			margin: 0;
			padding: 20vw 5vw 60vw;
			overflow: scroll;
			background-color: color(bright);
			z-index: 100;

			@include breakpoint($breakpoint-mid) {
				padding: 5vw;
			}
		} */

		&__item {
			position: static;
			list-style-type: none;
			margin: 0;
			padding: 0;

			@include breakpoint($breakpoint-mid) {
				display: flex;
				align-items: center;
			}

			// &--mobile {
			// 	@include breakpoint(sl) {
			// 		display: none;
			// 	}
			// }
		}

		&__button {
			margin: 10vw 0;

			&.btn {
				background-color: color(red500);
				border-color: color(red500);
				padding: 6px 12px;
				height: auto;
				min-height: auto;
				font-size: inherit;
				line-height: inherit;

				@include breakpoint(lg) {
					padding: 6px 36px;
				}

				@include breakpoint(xl) {
					padding: 9px 36px;
				}

				&:hover,
				&:focus {
					background-color: color(red600);
					border-color: color(red600);
				}
			}

			/* @include breakpoint(sl) {
				margin: auto 20px auto 1.25vw;
			} */

			@include breakpoint(mid) {
				margin: auto 20px;
			}
		}

		&__link {
			@include font-family(sansbold);
			padding: 2vw 0;
			position: relative;
			display: block;
			line-height: 1;
			color: color(black);
			border-bottom: none !important;

			@media (max-width: ($breakpoint-sl - 1px)) {
				// min-height: 20vw;
				// font-size: 7vw;
				display: flex;
				align-items: center;

				&:focus,
				&:hover,
				&:active {
					border-bottom-color: transparent;
				}
			}

			@include breakpoint(sl) {
				padding: 2vw 1vw;
			}



			//border
			&::before {
				content: '';
				position: absolute;
				height: 3px;
				right: 1vw;
				bottom: 1vw;
				left: 1vw;
				background: color(red500);
				display: none;
			}

			&:hover,
			&:focus,
			&.is-active,
			#{$self}__item.is-active>& #{$self}__item.is-open>& {
				color: color(red500);

				&::before {
					@include breakpoint(sl) {
						display: block;
					}
				}
			}

			// secondary variant used for toplink items in mobile-menu
			&--secondary {

				@media (max-width: ($breakpoint-sl - 1px)) {
					font-size: 4vw;
					min-height: 0;
					padding: 0;
					margin-bottom: 5vw;
				}
			}

			.menu__item--has-submenu {
				display: inline-flex;
				align-items: center;

				&::after {
					content: '';
					display: block;
					margin-left: 8px;
					width: 11px;
					height: 6px;
					background-size: contain;
					background-repeat: no-repeat;
					background-image: url("data:image/svg+xml,%3Csvg width='11' height='6' viewBox='0 0 11 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.99.772l.057.066 4.25 4.893a.801.801 0 001.2 0L10.744.847l.072-.081A.483.483 0 0010.9.494.507.507 0 0010.381 0H1.42A.507.507 0 00.9.494c0 .103.034.2.09.278z' fill='%23007E91' fill-rule='nonzero'/%3E%3C/svg%3E");
				}
			}
		}

		.menu__item--has-submenu>.menu__link {
			@include breakpoint(mid) {
				padding-right: 34px;
			}
		}

		.menu__item--has-submenu>.menu__link::after {
			@include background-svg('<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M18 8c.563 0 1 .438 1 1 0 .25-.125.5-.313.688l-5.968 6.03A1.044 1.044 0 0112 16c-.25 0-.531-.094-.719-.281L5.312 9.688C5.125 9.5 5 9.25 5 9c0-.563.438-1 1-1 .281 0 .5.094.719.281L12 13.562l5.281-5.28A.99.99 0 0118 8z" fill="#282D37" fill-rule="evenodd"/></svg>');
		}

		.menu__item--has-submenu>.menu__link:focus::after,
		.menu__item--has-submenu>.menu__link:hover::after {
			@include background-svg('<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M18 8c.563 0 1 .438 1 1 0 .25-.125.5-.313.688l-5.968 6.03A1.044 1.044 0 0112 16c-.25 0-.531-.094-.719-.281L5.312 9.688C5.125 9.5 5 9.25 5 9c0-.563.438-1 1-1 .281 0 .5.094.719.281L12 13.562l5.281-5.28A.99.99 0 0118 8z" fill="#ec0016" fill-rule="evenodd"/></svg>');
		}

		.menu__item--has-submenu.is-open>.menu__link::after {
			// outline: 1px solid red;
			@include background-svg('<svg width="14" height="8" viewBox="0 0 14 8" xmlns="http://www.w3.org/2000/svg"><path d="M13 8c.563 0 1-.438 1-1 0-.25-.125-.5-.313-.688L7.719.282A1.044 1.044 0 0 0 7 0c-.25 0-.531.094-.719.281L.312 6.312C.125 6.5 0 6.75 0 7c0 .563.438 1 1 1 .281 0 .5-.094.719-.281L7 2.438l5.281 5.28A.99.99 0 0 0 13 8Z" fill="#ec0016" fill-rule="evenodd"/></svg>');
		}

		&__lang-menu {
			display: flex;
			list-style-type: none;
			margin: 0;
			padding: 0;

			.menu__item {
				display: flex;
				align-items: center;
				margin-right: 2vw;

				&--globe>img {
					width: 4vw;
				}
			}

			.menu__link {
				margin-bottom: 0;
				padding: 10px 0;
				text-transform: uppercase;
			}
		}
	}
}



//
// Submenu
//
.header--large {
	.submenu {
		$self: &;
		display: none;
		background: color(white);
		flex-direction: column;
		padding-bottom: 5vw;

		@include breakpoint(sl) {
			border-top: 1px solid color(coolgray200);
			position: absolute;
			flex-direction: column;
			top: auto;
			bottom: 0;
			left: 0;
			right: 0;
			max-width: none;
			width: 100%;
			padding: 0;
			margin-top: 0;
			box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 8px 8px 0 rgba(0, 0, 0, 0.10), 0 8px 16px 0 rgba(0, 0, 0, 0.15);
			transform: translateY(100%);
		}

		@include breakpoint(md) {
			flex-direction: row;
		}

		@include breakpoint(max) {
			// top: auto;
			// min-width: auto;
		}

		// white background and shadow element for bottom shadow
		&::before,
		&::after {
			content: '';
			position: absolute;
			right: 0;
			left: 0;
			display: none;

			@include breakpoint(sl) {
				display: block;
			}
		}

		&::before {
			// display: none;
			// height: 0;
			// top: 0;
			height: 40px;
			bottom: 0;
			// box-shadow: $box-shadow--1;
			background: none;

			@include breakpoint(sl) {
				display: block;
				width: 100%;
			}
		}

		&::after {
			top: 0;
			bottom: 0;
			background: color(white);
		}

		.menu__item.is-open & {
			display: flex;
		}

		&__main {
			position: relative;
			z-index: 1;
			display: flex;
			flex-direction: column;

			@include breakpoint(sl) {
				padding-bottom: 3.75vw;
				flex-wrap: wrap;
				flex-direction: row;
			}

			@include breakpoint(md) {
				flex: 0 0 65%;
			}
		}

		&__col {

			@include breakpoint(sl) {
				margin-left: 5vw;

				&--half {
					flex: 0 0 42.5vw;
				}

				&--full {
					flex: 0 0 90vw;
				}

				&--max {
					flex: 0 0 90vw;
				}
			}

			@include breakpoint(md) {
				margin-left: 5vw;

				&--half {
					flex: 0 0 25vw;
				}

				&--full {
					flex: 0 0 55vw;
				}

				&--max {
					flex: 0 0 60vw;
				}
			}
		}



		&__aside {
			position: relative;
			z-index: 1;
			margin-top: 5vw;

			.submenu__link-wrapper {
				display: block;
			}

			@include breakpoint(sl) {
				padding: 0 5vw 3.75vw;
				background: color(coolgray100);
				margin-top: 0;
				flex: 0 0 100%;
			}

			@include breakpoint(md) {
				margin-top: 0;
				flex: 0 0 35%;

				.msie & {
					flex-basis: auto;
					width: 35%;
				}
			}

			.submenu__link {
				background: color(white);

				@media (max-width: ($breakpoint-sl - 1px)) {
					padding-left: 5vw;
				}

				&:hover,
				&:focus {
					color: color(black);
					background: color(coolgray200);
				}
			}

		}

		//wraps different links
		&__category {
			margin-bottom: 5vw;

			@include breakpoint(sl) {
				margin-top: 2vw;
				margin-bottom: 0;
			}
		}

		&__category-title {
			@include font-family(regular);
			color: color(warmgray600);
			font-size: 12px;
			letter-spacing: 0.08em;
			text-transform: uppercase;
		}

		&__link-wrapper {

			display: grid;
			grid-template-columns: repeat(1, 1fr);

			@include breakpoint(sl) {
				grid-template-columns: repeat(2, 1fr);
			}

			@include breakpoint(md) {
				padding-right: 5vw;
			}

			column-gap: 5vw;

			img {
				@include breakpoint(sl) {
					margin-top: 1.25vw;
					margin-bottom: 1.25vw;
					padding: 14px 0;
				}
			}

			p {
				@include font-family(sansbold);
				line-height: 1.38;

				@include breakpoint(sl) {
					margin-top: 1.25vw;
					margin-left: -12px;
					margin-right: -12px;
					margin-bottom: 1.25vw;
					padding: 14px 12px;
					//background: color(transparent);
				}
			}
		}

		&__main--without-aside {

			@include breakpoint(md) {
				flex: 0 0 100%;
			}

			.submenu__link-wrapper {
				padding-right: 0;
				grid-template-columns: repeat(1, 1fr);

				@include breakpoint(sl) {
					grid-template-columns: repeat(2, 1fr);
				}

				@include breakpoint(md) {
					grid-template-columns: repeat(3, 1fr);
				}
			}

			.submenu__col {
				&--max {
					flex: 0 0 90vw;
				}

				@include breakpoint(sl) {
					margin-left: 5vw;

					&--max {
						flex: 0 0 90vw;
					}
				}

				@include breakpoint(md) {
					margin-left: 5vw;

					&--max {
						flex: 0 0 90vw;
					}
				}
			}
		}

		//map inside
		.interactive-map {
			@include breakpoint(sl) {
				margin-top: 2vw;
			}
		}

		// contains title and options icon and description-text
		&__link {
			// @include font-family(sansbold);
			padding: 3.75vw 5vw 3.75vw 2.5vw;
			background: transparent;
			font-size: 16px;
			display: flex;
			margin-top: 2.5vw;
			margin-left: -2.5vw;
			margin-right: -2.5vw;
			line-height: 1.4;
			border-radius: $border-radius;
			border-bottom: 3px solid transparent;
			color: color(black);

			@media (max-width: ($breakpoint-sl - 1px)) {
				background: color(coolgray100);
			}

			@include breakpoint(sl) {
				margin-top: 1.25vw;
				margin-left: -12px;
				margin-right: -12px;
				padding: 14px 12px;
				//background: color(transparent);
			}

			&:hover,
			&:focus {
				color: color(black);
				background: color(coolgray200);
				border-bottom: 3px solid transparent;
			}

			&.is-active {
				background: color(coolgray200);
				border-bottom: 3px solid transparent;
			}

			span {
				padding-bottom: 0;
			}

			&+& {
				margin-top: 10px;
			}
		}

		&__link-icon,
		svg {
			flex: 0 0 10vw;
			width: 10vw;
			height: 10vw;
			margin-right: 5vw;
			display: block;

			@include breakpoint(sl) {
				flex: 0 0 40px;
				width: 40px;
				height: 40px;
				margin-right: 20px;
			}

			@include breakpoint(md) {
				flex: 0 0 60px;
				width: 60px;
				height: 60px;
			}
		}

		// &__link-text {}

		&__link-description {
			@include font-family(regular);
			display: block;
			color: color(warmgray600);
			margin-top: 4px;
			font-size: 13px;
			line-height: 1.38;
		}

		//map component inside menu
		.map {
			margin-top: 30px;

			@media (max-width: ($breakpoint-md - 1px)) {
				display: none;
			}
		}

	}
}


//
// Toogle open / close States
//
.header--large {
	.menu__item.is-open {

		.menu__link {
			color: color(black);
		}

		// > .menu__link {
		// 	&::after {
		// 		@include background-svg('<svg width="14" height="8" viewBox="0 0 14 8" xmlns="http://www.w3.org/2000/svg"><path d="M13 8c.563 0 1-.438 1-1 0-.25-.125-.5-.313-.688L7.719.282A1.044 1.044 0 0 0 7 0c-.25 0-.531.094-.719.281L.312 6.312C.125 6.5 0 6.75 0 7c0 .563.438 1 1 1 .281 0 .5-.094.719-.281L7 2.438l5.281 5.28A.99.99 0 0 0 13 8Z" fill="#007E91" fill-rule="evenodd"/></svg>');
		// 	}
		// }

		.submenu {
			display: flex;
		}
	}

}



//
// Hamburger Menü Icon
//
.header--large {
	.showmenu__toggle {
		border: none;
		background: none;
		cursor: pointer;
		font-family: inherit;
		position: relative;
		display: block;
		margin: 16px 0;
		padding: 0;
		width: 24px;
		height: 20px;
		z-index: 200;

		@include breakpoint($breakpoint-mid) {
			display: none;
		}

		&:hover {
			cursor: pointer;
		}

		.header__navigation--bigger & {
			@include breakpoint(mid) {
				display: block;
			}

			@include breakpoint(lg) {
				display: none;
			}
		}
	}
}



//
//
//
.header--large {
	.showmenu__icon {

		&,
		&::before,
		&::after {
			display: block;
			position: absolute;
			width: 24px;
			height: 3px;
			content: '';
			cursor: pointer;
			background-color: color(black);
		}

		top: 0px;
		background: #000;

		&::before {
			top: 8px;
		}

		&::after {
			top: 16px;
			width: 16px;
		}
	}

	.showmenu__input {
		display: none;

		// &:checked+.menu {}
	}
}