.alpine-modal-backdrop {
	background: rgba(0, 0, 0, 0.8);
	position: fixed;
	inset: 0;
	z-index: 3000;
	pointer-events: none;
	display: flex;
	align-items: flex-start;
	justify-content: flex-end;
	padding: 1em 1em 0 0;
	font-size: 15px;

	svg {
		color: #fff;
		width: 1em;
		height: 1em;
	}

	@include breakpoint(sm) {
		font-size: 25px;
	}
}

.alpine-modal-popup {
	position: fixed;
	inset: 0;
	z-index: 3001;
	display: grid;
	grid-template: "left content right" auto / 1fr auto 1fr;

	> * {
		align-self: center;
		justify-self: center;
	}

	.alpine-modal-popup__content {
		grid-area: content;
	}
}

.alpine-modal-popup__close {
	position: absolute;
	top: 0;
	right: 0;
	background: none;
	border: 0;
	cursor: pointer;
	font-size: 40px;
	width: 1em;
	height: 1em;
	color: #fff;

	&:focus {
		outline: none;
	}

	@include breakpoint(sm) {
		font-size: 60px;
	}

	@include breakpoint(mid) {
		font-size: 80px;
	}
}

.alpine-modal-popup__button {
	background: none;
	border: 0;
	color: #fff;
	display: flex;
	text-align: left;
	line-height: 1.2;
	font-size: 18px;
	align-items: center;
	justify-self: center;
	cursor: pointer;
	padding: 0;

	> span {
		display: none;

		@include breakpoint(md) {
			display: block;
		}
	}

	svg {
		width: 40px;
		height: 40px;
	}

	&--prev {
		grid-area: left;

		svg {
			transform: rotate(180deg);
		}

		@include breakpoint(md) {
			justify-self: end;
		}
	}

	&--next {
		grid-area: right;

		@include breakpoint(md) {
			justify-self: start;
		}
	}
}

.alpine-modal-popup__content {
	background: #fff;
	border-radius: 6px;
	max-width: 640px;
	overflow: hidden;
	max-height: calc(100% - 40px);
	display: flex;
	flex-direction: column;

	&--border {
		border: 2px dashed currentColor;
	}

	h3 {
		@include headline2;
		text-align: center;

		small {
			@include font-family(headlight);
			display: block;
			font-size: 0.8em;
			margin-bottom: 0.6em;
		}

		&::after {
			content: "";
			width: 100%;
			max-width: 80px;
			height: 10px;
			border-radius: 5px;
			background: color(red500);
			display: block;
			margin: 0.6em auto;
		}
	}

	p:last-child {
		margin-bottom: 0;
	}

	&--first {
		margin-left: 40px;
	}

	&--last {
		margin-right: 40px;
	}

	@media (min-width: 720px) {

		&--first,
		&--last {
			margin-left: 0;
			margin-right: 0;
		}
	}

	@include breakpoint(md) {
		margin-left: 20px;
		margin-right: 20px;
	}
}

.alpine-modal-popup__scroller {
	flex-grow: 1;
	overflow-y: auto;

	> div {
		padding: 20px 40px 40px;
	}
}
