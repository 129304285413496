.teasers-with-icon {
	display: flex;
	flex-wrap: wrap;
	margin-left: -15px;
	margin-right: -15px;
	justify-content: center;
}

.teasers-with-icon-item {
	padding: 15px;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	position: relative;
	flex: 0 0 100%;

	@include breakpoint(sm) {
		flex: 0 0 50%;
	}

	@include breakpoint(md) {
		flex: 0 0 25%;
	}

	> img,
	> svg,
	> video {
		max-width: 160px;
		max-height: 160px;
		aspect-ratio: 1;
		object-fit: contain;
	}

	button {
		cursor: pointer;
		border: 0;
		background: transparent;
		padding: 0;
		display: flex;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			.-backend & {
				display: none;
			}
		}


	}
}

.teasers-with-icon-item__link {
	text-decoration: none;

	&:hover,
	&:focus {
		text-decoration: underline;
	}
}

.teasers-with-icon-item__link--dialog {
	position: relative;
	z-index: 10;
}

.teasers-with-icon-item__link--full:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	.-backend & {
		display: none;
	}
}

.teasers-with-icon-item__text {
	@include font-family(headblack);

	margin: 4em 0 1em;
	flex: 1;
}
