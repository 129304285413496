// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------


//
// Debugging
//
// Swap rows for easy enable / disable
//
$htmlLinting: true;
$htmlLinting: false;
$showPesticide: true;
$showPesticide: false;
$showGridDisplayer: true;
$showGridDisplayer: false;
$media-queries-debugging: true;
$media-queries-debugging: false;



//
// Colors
// Get one Value: .test { color: map-get($colors, orange); }
// or use the function: .test { color: color(orange); }
//
$colors: (
	black: #000,
	white: #fff,
	dark: #000,
	bright: #fff,

	red600: #C50014,
	red500: #ec0016,

	// Orange and green is used for calendar
	orange500: #f39200,

	green500: #408335,
	lightgreen500: #63a615,

	cyan700: #006A96,
	cyan600: #0087B9,
	cyan400: #55B9E6,
	cyan200: #BBE6F8,

	warmgray800: #38342F,
	warmgray700: #4F4B41,
	warmgray600: #747067,
	warmgray500: #858379,
	warmgray400: #9C9A8E,
	warmgray300: #BCBBB2,
	warmgray200: #DDDED6,
	warmgray100: #F5F4F1,

	coolgray800: #131821,
	coolgray700: #282D37,
	coolgray600: #3C414B,
	coolgray500: #646973,
	coolgray400: rgb(135, 140, 150),
	coolgray300: #AFB4BB,
	coolgray200: #D7DCE1,
	coolgray100: #F0F3F5,
	coolgray050: #fafbfc,

	coolgray400Op50: rgb(195, 197.5, 202.5),
);


//
// Font-Sizes
//
$textminbreakpoint: 400;
$textmidbreakpoint: 800;

$textfontsizemin:   14;
$textlineheightmin: 24;
$textfontsizemax:   18;
$textlineheightmax: 32;

$textcompactfontsizemin:   14;
$textcompactlineheightmin: 20;
$textcompactfontsizemax:   16;
$textcompactlineheightmax: 24;

$h1fontsizemin:   28;
$h1lineheightmin: 32;
$h1fontsizemid:   36;
$h1lineheightmid: 40;
$h1fontsizemax:   48;
$h1lineheightmax: 56;

$h2fontsizemin:   18;
$h2lineheightmin: 24;
$h2fontsizemid:   24;
$h2lineheightmid: 32;
$h2fontsizemax:   32;
$h2lineheightmax: 40;

$h3fontsizemin:   16;
$h3lineheightmin: 24;
$h3fontsizemax:   20;
$h3lineheightmax: 32;

$textsmallfontsizemin:   10;
$textsmalllineheightmin: 12;
$textsmallfontsizemax:   14;
$textsmalllineheightmax: 24;


$transition-time: .2s;
$transition-function: ease-in-out;
$transition: all $transition-time $transition-function;

$box-shadow--1: 0 3px 4px 0 rgba(0,0,0,0.05), 0 0 8px 0 rgba(0,0,0,0.10), 0 8px 16px 0 rgba(0,0,0,0.15);
$box-shadow--2: 0 2px 4px 0 rgba(0,0,0,0.05), 0 0 4px 0 rgba(0,0,0,0.10), 0 3px 8px 0 rgba(0,0,0,0.15);
$box-shadow--3: 0 3px 4px 0 rgba(0,0,0,0.05), 0 0 8px 0 rgba(0,0,0,0.10), 0 8px 16px 0 rgba(0,0,0,0.15);

$border-radius: 6px;


//
// Breakpoints
//
$smallestBreakpoint: xxs;
$breakpoint-xs:  414px; // iPhone Plus
$breakpoint-sm:  640px;
$breakpoint-sl:  768px;
$breakpoint-md: 1024px;
$breakpoint-lg: 1280px;
$breakpoint-xl: 1520px;

$breakpoint-min: 400px;
$breakpoint-mid: 800px;
$breakpoint-max: 1600px;



//
// Spacer Value
//
$spacer: 1vw;




//
// Autoprefixer
// enable grid translations for IE
// Used as control comment
// https://github.com/postcss/autoprefixer#does-autoprefixer-polyfill-grid-layout-for-ie
// https://css-tricks.com/css-grid-in-ie-duplicate-area-names-now-supported/#article-header-id-11
//

/* Globally enable grid prefixes */
/* autoprefixer grid: on */
